import React from "react";
import './styles/home.css';
import './styles/portfolio.css';
import './styles/contact.css';
import './styles/skil.css';
import './styles/slyder.css';

function App() {
  return (
    <>
      
    </>
  );
}

export default App;